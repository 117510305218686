// node modules
import isMobile from 'ismobilejs';
import PubSub from 'pubsub-js';

// constants
import { IMAGE_EVENTS } from 'components/image/image.scripts';
import { COVER_SLIDER_EVENTS } from 'components/cover/cover.scripts';
import { DOCUMENT_ELEMENT, SELECTORS } from '@/scripts/constants';

// assets
import './preloader.styles.scss';

const preloader = document.querySelector('[data-preloader]');
const preloaderToken = 'hasSeenPreloader';

const getPreloaderToken = () => !!sessionStorage.getItem(preloaderToken);

const setPreloaderToken = () => {
  sessionStorage.setItem(preloaderToken, 'true');
};

const loadImagesForce = () => {
  if (isMobile().any) {
    return;
  }
  PubSub.publish(IMAGE_EVENTS.loadAll);
};

const showPreloaderAnimation = (timeout = 4000) => new Promise((resolve) => {
  preloader.classList.add('is-animated');
  setTimeout(() => {
    resolve();
  }, timeout);
});

const removePreloader = () => {
  DOCUMENT_ELEMENT.classList.remove(SELECTORS.no_scroll);
  DOCUMENT_ELEMENT.classList.remove(SELECTORS.has_preloader);
  PubSub.publish(COVER_SLIDER_EVENTS.start);
  const coverSection = document.querySelector('[data-cover-section]');
  coverSection.querySelectorAll('.is-animated').forEach((el) => {
    el.classList.add('is-animated_in');
  });
};

export const initPreloader = async () => {
  loadImagesForce();
  if (!getPreloaderToken()) {
    setPreloaderToken();
    await showPreloaderAnimation();
  }
  removePreloader();
};
