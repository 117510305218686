import { smoothScroll } from './smooth-scroll';

const ms = 300;
let timeout = null;

export default (onEnter, onLeave) => (entry) => {
  if (entry.isIntersecting) {
    timeout = setTimeout(() => {
      onEnter(entry.target);
      timeout = null;
    }, ms);
    entry.target.dataset.isIntersecting = '';
    smoothScroll.onStop(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      onEnter(entry.target);
    });
  } else {
    clearTimeout(timeout);
    onLeave(entry.target);
    delete entry.target.dataset.isIntersecting;
  }
};
