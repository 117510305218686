import '@babel/polyfill';
import Shuffle from 'shufflejs';
import { smoothScroll } from '@/scripts/utils/smooth-scroll';

function init(container) {
  const filterButtons = document.querySelectorAll('[data-blog-filter]');
  const sizer = container.querySelector('.my-sizer-element');

  const shuffle = new Shuffle(container, {
    itemSelector: '.blog-item',
    sizer,
    gutterWidth: 32,
    buffer: 72,
  });

  function onClick(e) {
    const btn = e.currentTarget;
    const isActive = btn.classList.contains('is-active');
    const btnGroup = btn.getAttribute('data-group');

    let filterGroup;
    if (!isActive) {
      filterButtons.forEach((el) => el.classList.remove('is-active'));
      btn.classList.add('is-active');
      filterGroup = btnGroup;
    } else {
      btn.classList.remove('is-active');
    }

    shuffle.filter(filterGroup);
  }

  filterButtons.forEach((button) => {
    button.addEventListener('click', onClick, false);
  });

  shuffle.on(Shuffle.EventType.LAYOUT, () => {
    setTimeout(smoothScroll.reflowSections, 200);
    setTimeout(smoothScroll.reflowSections, 1000);
  });
}

export const blogShuffle = () => {
  const container = document.querySelector('[data-blog-grid]');

  if (container) {
    window.addEventListener('load', () => init(container));
  }
};
