const listeners = [];

document.addEventListener('keyup', (e) => {
  if (e.key === 'Escape') {
    listeners.forEach((l) => l());
  }
});

export function onEscape(listener) {
  listeners.push(listener);
  return () => {
    const i = listeners.indexOf(listener);
    listeners.splice(i, 1);
  };
}
