import './gallery.styles.scss';
// node modules
import anime from 'animejs';
import PubSub from 'pubsub-js';

// helpers
import { drawImageProp } from 'components/cover/cover.helpers';
import { debounce } from '@/scripts/utils/helpers';

export const COVER_SLIDER_EVENTS = {
  start: 'cover-slider.start',
};

class CoverSlide {
  constructor({
    container, img, parts, easings, duration = 1500,
  }) {
    this.container = container;
    this.img = img;
    this.parts = parts;
    this.easings = easings;
    this.cropsStart = {};
    this.crops = {};
    this.duration = duration;
    this.dpi = window.devicePixelRatio;
    this.vw = this.container.offsetWidth + 2;
    this.vh = this.container.offsetHeight + 2;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.init();
    this.resize = debounce(this.resize.bind(this), 50);
  }

  resetCrops() {
    for (let i = 0; i < this.parts; i += 1) {
      this.crops[`x${i}`] = 0;
    }
    this.updateRender();
  }

  setSlide(slide) {
    if (slide === 'current') {
      this.canvas.style.zIndex = 2;
      this.canvas.classList.add('is-current');
      this.canvas.classList.remove('is-prev');
      this.canvas.classList.remove('is-hidden');
    } else if (slide === 'prev') {
      this.canvas.style.zIndex = 1;
      this.canvas.classList.add('is-prev');
      this.canvas.classList.remove('is-current');
      this.canvas.classList.remove('is-hidden');
    } else {
      this.canvas.classList.add('is-hidden');
    }
  }

  reveal() {
    this.resetCrops();
    for (let i = 0; i < this.parts; i += 1) {
      anime({
        targets: this.crops,
        [`x${i}`]: 1,
        easing: this.easings[i],
        duration: this.duration,
        update: () => {
          this.render(i);
        },
      });
    }
  }

  updateRender() {
    for (let i = 0; i < this.parts; i += 1) {
      this.render(i);
    }
  }

  render(i) {
    const { width, height } = this.canvas;
    this.ctx.clearRect(0, i * height / this.parts, width, height / this.parts);
    const region = new Path2D();
    region.rect(
      0,
      i * height / this.parts - 1,
      width * this.crops[`x${i}`],
      height / this.parts + 2,
    );

    this.ctx.save();
    this.ctx.clip(region, 'evenodd');
    drawImageProp(this.ctx, this.img);
    this.ctx.restore();
  }

  resize() {
    this.vw = this.container.offsetWidth + 2;
    this.vh = this.container.offsetHeight + 2;
    this.canvas.width = this.vw * this.dpi;
    this.canvas.height = this.vh * this.dpi;
    this.canvas.style.width = `${this.vw}px`;
    this.canvas.style.height = `${this.vh}px`;
    this.updateRender();
  }

  initCanvas() {
    this.resize();
    this.img.style.display = 'none';
    this.container.appendChild(this.canvas);
    window.addEventListener('resize', () => this.resize());
  }

  init() {
    this.resetCrops();
    this.initCanvas();
  }
}

class CoverSlider {
  constructor(el) {
    this.el = el;
    this.mainImagesContainer = el.querySelector('[data-cover-slider-main-images]');
    this.subImagesContainer = el.querySelector('[data-cover-slider-sub-images]');
    this.titlesContainer = el.querySelector('[data-cover-slider-titles]');
    this.paginationContainer = el.querySelector('[data-cover-slider-pagination]');
    this.currentSlideNumber = el.querySelector('[data-current-slide]');
    this.slidesCount = el.querySelector('[data-slides-count]');
    this.slidesLine = el.querySelector('[data-slides-line]');
    this.sliderLinks = el.querySelectorAll('[data-cover-slide-link]');
    this.sliderLink = el.querySelector('[data-cover-slider-link]');
    if (!this.mainImagesContainer
      || !this.subImagesContainer
      || !this.titlesContainer
      || !this.paginationContainer
      || !this.currentSlideNumber
      || !this.slidesCount
      || !this.slidesLine
      || !this.sliderLinks.length
      || !this.sliderLink
    ) {
      return;
    }
    this.controls = {
      next: this.el.querySelector('[data-cover-slider-control="next"]'),
    };
    this.activeIndex = 0;
    this.isAnimating = false;
    this.activeTitle = null;
    this.interval = null;
    this.mainImages = Array
      .from(this.mainImagesContainer.querySelectorAll('img'))
      .map((img) => new CoverSlide({
        container: this.mainImagesContainer,
        img,
        parts: 4,
        easings: [
          'cubicBezier(0.4, 0, 0, 1)',
          'cubicBezier(0.6, 0, 0.1, 1)',
          'cubicBezier(0.5, 0.1, 0, 1)',
          'cubicBezier(0.8, 0, 0, 1)',
        ],
      }));

    this.subImages = Array
      .from(this.subImagesContainer.querySelectorAll('img'))
      .map((img) => new CoverSlide({
        container: this.subImagesContainer,
        img,
        parts: 3,
        easings: [
          'cubicBezier(0.4, 0, 0, 1)',
          'cubicBezier(0.6, 0, 0.1, 1)',
          'cubicBezier(0.8, 0, 0, 1)',
        ],
      }));
    this.titles = Array.from(this.titlesContainer.querySelectorAll('[data-cover-slider-title]'));

    this.pagination = Array(this.mainImages.length)
      .fill(0)
      .map(() => {
        const item = document.createElement('div');
        item.classList.add('slider-pagination__item');
        this.paginationContainer.appendChild(item);
        return item;
      });
  }

  slideNext() {
    if (this.isAnimating) {
      return;
    }
    this.isAnimating = true;
    this.slidesLine.classList.remove('is-active');
    setTimeout(() => {
      this.slidesLine.classList.add('is-active');
    }, 1000);
    const currentSlide = this.activeIndex % this.mainImages.length;
    const prevSlide = (this.mainImages.length + this.activeIndex - 1) % this.mainImages.length;
    this.currentSlideNumber.textContent = `${currentSlide + 1}`.padStart(2, '0');
    this.slidesCount.textContent = `${this.mainImages.length}`.padStart(2, '0');
    this.sliderLink.href = this.sliderLinks[currentSlide].dataset.coverSlideLink;
    for (let i = 0; i < this.mainImages.length; i += 1) {
      this.pagination[i].classList.remove('is-active');
      if (i === currentSlide) {
        this.mainImages[i].setSlide('current');
        this.subImages[i].setSlide('current');
        this.titles[i].classList.add('is-current');
        this.titles[i].classList.remove('is-prev');
        this.titles[i].classList.remove('is-next');
        this.activeTitle = this.titles[i];
        this.pagination[i].classList.add('is-active');
      } else if (i === prevSlide) {
        this.mainImages[i].setSlide('prev');
        this.subImages[i].setSlide('prev');
        this.titles[i].classList.remove('is-current');
        this.titles[i].classList.add('is-prev');
        this.titles[i].classList.remove('is-next');
      } else {
        this.mainImages[i].setSlide('hidden');
        this.subImages[i].setSlide('hidden');
        this.mainImages[i].resetCrops();
        this.subImages[i].resetCrops();
        this.titles[i].classList.remove('is-current');
        this.titles[i].classList.remove('is-prev');
        this.titles[i].classList.add('is-next');
      }
    }

    this.mainImages[currentSlide].reveal();
    this.subImages[currentSlide].reveal();
    this.activeIndex += 1;
    setTimeout(() => {
      this.isAnimating = false;
    }, 1500);
  }

  autoplay() {
    this.interval = setInterval(() => {
      this.slideNext();
    }, 5000);
  }

  init() {
    this.controls.next.addEventListener('click', () => {
      this.slideNext();
    });
  }
}

export const initCoverSlider = () => {
  const coverSliderElement = document.querySelector('[data-cover-slider]');
  if (!coverSliderElement) {
    return;
  }
  const coverSlider = new CoverSlider(coverSliderElement);
  coverSlider.init();
  PubSub.subscribe(COVER_SLIDER_EVENTS.start, () => {
    coverSlider.slideNext();
    coverSlider.autoplay();
  });
};
