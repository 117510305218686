import './tabs.styles.scss';
import isMobile from 'ismobilejs';
import { SELECTORS } from '../../scripts/constants';

export function initTabs() {
  const line = document.querySelector('[data-tab-underline]');

  const setLine = (el) => {
    const position = el.getBoundingClientRect();

    line.setAttribute('style', `width: ${position.width}px; transform: translateX(${el.offsetLeft}px)`);
  };

  const clear = () => {
    const tabs = document.querySelectorAll('[data-tab]');

    tabs.forEach((item) => {
      item.classList.remove(SELECTORS.active);
      const { tab } = item.dataset;

      document.getElementById(tab).classList.remove(SELECTORS.active);
    });
  };

  const change = (item) => {
    clear();

    item.classList.add(SELECTORS.active);
    const { tab } = item.dataset;
    document.getElementById(tab).classList.add(SELECTORS.active);

    if (line && !isMobile().any) {
      setLine(item);
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  };

  const bindAll = () => {
    const tabs = document.querySelectorAll('[data-tab]');
    tabs.forEach((item) => {
      item.addEventListener('click', () => change(item));

      if ([...item.classList].includes('is-active') && line && !isMobile().any) {
        setLine(item);
      }

      if (line && !isMobile().any) {
        line.classList.add('visible');
      }
    });
  };

  bindAll();
}
