// node modules
import isMobile from 'ismobilejs';
import anime from 'animejs';

// helpers
import raf from '@/scripts/utils/raf';
import {
  scrollPos, isEditMode, debounce, clamp,
} from '@/scripts/utils/helpers';

class SmoothScroll {
  constructor() {
    this.app = document.querySelector('.app');
    this.fakeScroll = document.createElement('div');
    this.sections = [];
    this.s = scrollPos();
    this.cs = this.s;
    this.onNextFrame = undefined;
    this.ch = null;
    this.wy = null;
    this.sectionsAreCalculated = false;
    this.nextScrollAll = false;

    this.hoversAreEnabled = true;
    this.isScrolling = false;
    this.onStopHandlers = [];

    this.reflowSections = debounce(this.reflowSectionsImmediate.bind(this), 250);

    this.isEnabled = !isMobile().any && !isEditMode();
  }

  reflowSectionsImmediate() {
    this.wy = window.innerHeight;
    this.ch = this.app.clientHeight;
    this.sections.forEach((section) => {
      const h = section.el.clientHeight;
      section.rect = { top: section.el.offsetTop, height: h };
      section.parallax.forEach((p) => {
        p.cch = p.el.clientHeight;
        p.ot = p.el.offsetTop;
      });
    });
    this.fakeScroll.style.height = `${this.ch}px`;
    this.sectionsAreCalculated = true;
    this.nextScrollAll = true;
  }

  checkIfObserved(section) {
    const r = section.rect;
    const isObserved = (this.cs + this.wy >= r.top) && (r.top + r.height >= this.cs);
    if (section.disableOnNextTick) {
      section.disableOnNextTick = false;
      section.isObserved = isObserved;
    } else if (isObserved === false && section.isObserved === true) {
      section.disableOnNextTick = true;
    } else {
      section.isObserved = isObserved;
    }
  }

  toggleHovers(enable) {
    this.hoversAreEnabled = enable;
    document.body.style.pointerEvents = enable ? '' : 'none';
  }

  scrollFrame() {
    if (this.nextScrollAll) {
      this.nextScrollAll = false;
      this.animateScroll(false);
      return;
    }
    if (!this.sectionsAreCalculated) return;
    if (this.onNextFrame) {
      this.onNextFrame();
      this.onNextFrame = undefined;
      return;
    }
    const ns = scrollPos();
    if (this.s !== ns) {
      this.s = ns;
    }
    if (Math.abs(this.cs - this.s) > 1) {
      this.cs += (this.s - this.cs) * 0.15;
      this.animateScroll(true);
      if (this.hoversAreEnabled) {
        this.toggleHovers(false);
      }
      this.isScrolling = true;
    } else if (this.cs !== this.s) {
      this.cs = this.s;
      this.animateScroll(true);
      if (!this.hoversAreEnabled) {
        this.toggleHovers(true);
      }
      this.isScrolling = false;
      this.onStopHandlers.forEach((h) => h());
      this.onStopHandlers.length = 0;
    }
  }

  setSections(nodeList) {
    if (!this.isEnabled) return;
    this.sectionsAreCalculated = false;
    this.sections = [];
    nodeList.forEach((el) => {
      const o = {};
      o.el = el;
      o.parallax = [];
      el.querySelectorAll('[data-parallax]').forEach((p) => o.parallax.push({ el: p }));
      this.sections.push(o);
    });
    this.reflowSections();
  }

  instantScrollTo(y, callback) {
    this.onNextFrame = callback;
    this.s = y;
    this.cs = y + 0.02;
    window.scrollTo({ top: y, behavior: 'instant' });
  }

  animateScroll(onlyObserved) {
    this.sections.forEach((section) => {
      const { rect } = section;
      const rectTop = rect.top - this.cs;

      this.checkIfObserved(section);

      if (onlyObserved && !section.isObserved) return;

      const transforms = {
        translateY: -this.cs,
        translateZ: 0,
      };
      if (section.el.dataset.scaleY !== undefined) {
        transforms.scaleY = section.el.dataset.scaleY;
      }
      anime.set(section.el, transforms);

      // ... shame ...
      section.parallax.forEach(({ el, cch, ot }) => {
        let y;
        let o;
        const scaleTransform = el.dataset.scaleY !== undefined ? ` scaleY(${el.dataset.scaleY})` : '';
        const amplitude = el.dataset.scaleY !== undefined
          ? 0.5 * (1 - Number(el.dataset.scaleY)) : 0;
        y = rectTop + cch - this.wy;

        o = Math.floor(100 * clamp(y / cch, 0, 1)) * 0.01;
        const transalteY = el.dataset.translateY || 0;
        switch (el.dataset.parallax) {
          case 'stick':
            el.style.opacity = (1 - (o * o));
            if (this.cs === this.s) {
              y = Math.round(y);
            }
            el.style.transform = `translateY(${-y + transalteY * o}px) translateZ(0)${scaleTransform}`;
            break;
          case 'stick-pure':
            y = Math.max(-rectTop + 0.5 * (this.wy - cch) - ot, 0);
            y = Math.min(y, rect.height - cch - ot);
            if (this.cs === this.s) {
              y = Math.round(y);
            }
            el.style.transform = `translateY(${y}px) translateZ(0)${scaleTransform}`;
            break;
          case 'fadeout':
            el.style.opacity = o * o;
            break;
          case 'slow-move':
            y = amplitude
              * cch
              * (rectTop + 0.5 * rect.height - (this.cs - 0.5 * this.wy))
              / rect.height;
            if (this.cs === this.s) {
              y = Math.round(y);
            }
            el.style.transform = `translateY(${y}px) translateZ(0)${scaleTransform}`;
            break;
          case '':
            y = Math.min(rectTop/* - hch */, y);
            if (this.cs === this.s) {
              y = Math.round(y);
            }
            el.style.transform = `translateY(${-Math.max(y, 0)}px) translateZ(0)`;
            break;
          case 'right':
            y = rectTop + 0.5 * cch - 0.75 * this.wy;
            o = 1 - Math.max(Math.min(y / this.wy, 1), 0); // eslint-disable-line
            if (this.cs === this.s) {
              y = Math.round(y);
            }
            if (el.dataset.locked === undefined) {
              el.style.transform = `translateX(${Math.max(0.3 * y, 0)}px) translateZ(0)`;
              el.style.opacity = o;
            }
            if (y < 0) {
              el.dataset.locked = '';
            }
            break;
          default:
          //
        }
      });
    });
  }

  startup() {
    if (!this.isEnabled) {
      return;
    }
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    document.body.appendChild(this.fakeScroll);
    this.app.classList.add('app_fixed');
    raf(this.scrollFrame.bind(this));
    window.addEventListener('resize', this.reflowSections);
  }

  onStop(handler) {
    if (!this.isScrolling) {
      handler();
    } else {
      this.onStopHandlers.push(handler);
    }
  }
}

export const smoothScroll = new SmoothScroll();
