/**
 * Add invalid class on input wrapper div based on HTML5 validation of child input
 */

// https://pageclip.co/blog/2018-02-20-you-should-use-html5-form-validation.html
// https://github.com/Pageclip/valid-form

const INVALID_CLASS = 'invalid';

function debounce(fn, ms) {
  let timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, ms);
  };
}

export default function (container) {
  container.querySelectorAll('[data-validateable-block]').forEach((block) => {
    const input = block.querySelector('[data-validateable-input]');
    if (!input) return;

    input.addEventListener('invalid', () => {
      block.classList.add(INVALID_CLASS);
    });
    input.addEventListener('input', () => {
      if (input.validity.valid) {
        block.classList.remove(INVALID_CLASS);
      }
    });
  });

  // focus first invalid field
  container.querySelectorAll('[data-focus-first-invalid]').forEach((el) => {
    const focusFirst = debounce(() => {
      const invalidNode = el.querySelector(':invalid');
      if (invalidNode) invalidNode.focus();
    }, 100);
    el.querySelectorAll('[data-validateable-input]').forEach((input) => input.addEventListener('invalid', focusFirst));
  });
}
