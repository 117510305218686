import isMobile from 'ismobilejs';
import { isEditMode } from '@/scripts/utils/helpers';
import './letters.styles.scss';

const CLASS = 'show';

function childrenForEach(ch, cb) {
  for (let i = 0; i < ch.length; i += 1) {
    cb(ch[i], i);
  }
}

function animateLine(line, position) {
  childrenForEach(line.children, (el, i) => {
    let delay = getComputedStyle(el).getPropertyValue('--delay');
    delay = parseFloat(delay);
    delay += position / 1000 + i * 0.4 / Math.min(line.children.length, 20);
    el.style.setProperty('--delay', `${delay}s`);
  });
}

function splitLine(line, splitter = '') {
  const letters = line.textContent
    .replace(/\s/g, ' ')
    .split(splitter);

  line.textContent = '';
  letters.forEach((char) => {
    const span = document.createElement('span');
    line.appendChild(span);
    span.textContent = char;
  });
  line.classList.add('letters');
  line.classList.remove(CLASS);
  childrenForEach(line.children, (el) => el.classList.remove(CLASS));
}

function splitWords(el) {
  const words = el.textContent
    .trim()
    .replace(/\s/g, ' ')
    .split(' ')
    .filter((w) => !!w);
  el.textContent = '';
  words.forEach((word) => {
    const span = document.createElement('span');
    const whitespace = document.createElement('span');
    span.classList.add('word');
    whitespace.classList.add('word');
    el.appendChild(span);
    el.appendChild(whitespace);
    span.textContent = word;
  });
}

function splitLetters(el) {
  const letters = el.textContent
    .trim()
    .split('');
  el.textContent = '';
  letters.forEach((letter) => {
    const span = document.createElement('span');
    span.classList.add('letter');
    el.appendChild(span);
    span.textContent = letter;
  });
}

export function init(el) {
  const { linesSelector, staggerInterval = 0 } = el.dataset;
  const splitter = 'gnewkghn';
  if (linesSelector) {
    if (linesSelector === '.word') {
      splitWords(el);
    }
    const lines = el.querySelectorAll(linesSelector);
    lines.forEach((line, i) => {
      splitLine(line, splitter);
      if ('splitLetters' in el.dataset) {
        splitLetters(line);
      }
      if ('splitWords' in el.dataset) {
        splitWords(line);
      }
      animateLine(line, i * staggerInterval);
    });
  } else {
    splitLine(el, splitter);
    animateLine(el);
  }
}

export function reset(el) {
  const { linesSelector } = el.dataset;
  if (linesSelector) {
    const lines = el.querySelectorAll(linesSelector);
    lines.forEach((line) => {
      line.classList.remove(CLASS);
      childrenForEach(line.children, (c) => c.classList.remove(CLASS));
    });
  } else {
    el.classList.remove(CLASS);
    childrenForEach(el.children, (c) => c.classList.remove(CLASS));
  }
}

export function reveal(el) {
  const { linesSelector, staggerInterval = 0 } = el.dataset;
  if (linesSelector) {
    const lines = el.querySelectorAll(linesSelector);
    lines.forEach((line, i) => {
      animateLine(line, i * staggerInterval);
    });
  } else {
    animateLine(el);
  }
}

export function dispose(el) {
  const { linesSelector } = el.dataset;
  if (linesSelector) {
    const lines = el.querySelectorAll(linesSelector);
    lines.forEach((line) => { line.innerHTML = line.textContent; });
  } else {
    el.innerHTML = el.textContent;
  }
}

export function initLetters(container) {
  if (isEditMode()) {
    return;
  }
  container.querySelectorAll('[data-stagger]').forEach((el) => {
    if ((isMobile().any && !el.hasAttribute('data-mobile-init'))) {
      return;
    }
    init(el);
  });
}
