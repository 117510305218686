import 'intersection-observer'; // safari <= 12.1 needs a polyfill
import isMobile from 'ismobilejs';
import debounceObservation from '@/scripts/utils/debounce-observation';
import { isEditMode } from '@/scripts/utils/helpers';

const debounceObservation2 = debounceObservation((el) => {
  el.classList.add('is-animated_in');
}, () => {});

const observer = new IntersectionObserver((entries) => {
  entries.forEach(debounceObservation2);
}, { threshold: 0, rootMargin: '0px 0px -180px' });

export const scrollAnimations = (container) => {
  if (isMobile().any || isEditMode()) {
    return;
  }
  const elements = container.querySelectorAll('[data-animated]');
  observer.disconnect();
  elements.forEach((element) => {
    element.classList.add('is-animated');
    observer.observe(element);
  });
};
