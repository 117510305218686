import measureScrollbar from 'scrollbarwidth';
import { DOCUMENT_ELEMENT } from '@/scripts/constants';

const scrollbarWidth = measureScrollbar();

export const toggleScrollbarWidth = (toggle) => {
  DOCUMENT_ELEMENT.style.setProperty(
    '--scrollbar-width',
    toggle ? `${scrollbarWidth}px` : 0,
  );
};
