import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import './slider.styles.scss';
import { smoothScroll } from '@/scripts/utils/smooth-scroll';

export const initSlider = (el) => {
  const { carouselSlider } = el.dataset;
  const carouselContainer = el.querySelector('[data-carousel-container]');

  if (!carouselContainer) {
    return null;
  }

  const buttons = Array.from(el.querySelectorAll('[data-carousel-button]'));

  let swiper;
  switch (carouselSlider) {
    case 'news': {
      swiper = new Swiper(carouselContainer, {
        speed: 850,
        navigation: {
          nextEl: buttons.find((b) => b.dataset.carouselButton === 'next'),
          prevEl: buttons.find((b) => b.dataset.carouselButton === 'prev'),
        },
        pagination: {
          el: el.querySelector('[data-slider-pagination]'),
          type: 'bullets',
        },
        slidesPerView: 3,
        spaceBetween: 40,
        lazy: {
          loadPrevNext: true,
          loadOnTransitionStart: true,
        },
        preloadImages: false,
        grabCursor: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        keyboard: true,
        freeMode: true,
        breakpoints: {
          1023: {
            slidesPerView: 2,
          },
          639: {
            slidesPerView: 1,
          },
        },
      });
      break;
    }
    case 'gallery': {
      swiper = new Swiper(carouselContainer, {
        loop: true,
        speed: 850,
        autoplay: true,
        navigation: {
          nextEl: buttons.find((b) => b.dataset.carouselButton === 'next'),
          prevEl: buttons.find((b) => b.dataset.carouselButton === 'prev'),
        },
        pagination: {
          el: el.querySelector('[data-slider-pagination]'),
          type: 'bullets',
        },
        slidesPerView: 1,
        spaceBetween: 104,
        lazy: {
          loadPrevNext: true,
          loadOnTransitionStart: true,
        },
        preloadImages: false,
        grabCursor: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        keyboard: true,
        breakpoints: {
          639: {
            slidesPerView: 1,
          },
        },
      });

      const currentSlideNumber = el.querySelector('[data-current-slide]');
      const slidesCount = el.querySelector('[data-slides-count]');
      const slidesLine = el.querySelector('[data-slides-line]');

      swiper.on('slideChange', () => {
        slidesLine.classList.remove('is-active');
        setTimeout(() => {
          slidesLine.classList.add('is-active');
        }, 1000);
        currentSlideNumber.textContent = `${swiper.realIndex + 1}`.padStart(2, '0');
        slidesCount.textContent = `${swiper.slides.length}`.padStart(2, '0');
      });

      break;
    }
    default:
      swiper = new Swiper(carouselContainer);
  }

  swiper.on('lazyImageReady', () => {
    smoothScroll.reflowSections();
  });

  return swiper;
};
