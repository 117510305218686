import './video.styles.scss';
import { SELECTORS } from '@/scripts/constants';
import { onEscape } from '@/scripts/utils/escape-key';

document.addEventListener('DOMContentLoaded', () => {
  const videoButton = document.querySelector('[data-video-button]');
  const videoClose = document.querySelector('[data-video-close]');
  const videoModal = document.querySelector('[data-video-modal]');
  if (!videoButton || !videoClose || !videoModal) {
    return;
  }
  let iframe = null;

  const close = () => {
    if (!iframe) {
      return;
    }
    iframe.remove();
    iframe = null;
  };
  videoButton.addEventListener('click', (e) => {
    e.preventDefault();
    iframe = document.createElement('iframe');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.setAttribute('src', videoButton.href);
    videoModal.appendChild(iframe);
    videoModal.classList.add(SELECTORS.active);
  });

  videoClose.addEventListener('click', () => {
    videoModal.classList.remove(SELECTORS.active);
    setTimeout(close, 300);
  });

  onEscape(() => {
    videoModal.classList.remove(SELECTORS.active);
    setTimeout(close, 300);
  });
});
