import { DOCUMENT_ELEMENT } from '@/scripts/constants';

export function scrollPos() {
  return window.pageYOffset || DOCUMENT_ELEMENT.scrollTop;
}

export function isAdminPanelVisible() {
  return !DOCUMENT_ELEMENT.classList.contains('ccm-toolbar-visible');
}

export function isEditMode() {
  return DOCUMENT_ELEMENT.classList.contains('ccm-edit-mode');
}

export function debounce(fn, ms) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(fn.bind(null, ...args), ms);
  };
}

export function clamp(val, min, max) {
  return Math.max(min, Math.min(val, max));
}
