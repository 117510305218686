// node modules
import LazyLoad from 'vanilla-lazyload';
import PubSub from 'pubsub-js';

// assets
import './image.styles.scss';

export const IMAGE_EVENTS = {
  loaded: 'images.loaded',
  update: 'images.update',
  load: 'images.load',
  loadAll: 'images.loadAll',
};

document.addEventListener('DOMContentLoaded', () => {
  const lazyLoad = new LazyLoad({
    elements_selector: '[data-lazy-loading]',
    threshold: 1000,
    callback_loaded(el) {
      PubSub.publish(IMAGE_EVENTS.loaded, { image: el });
    },
  });

  PubSub.subscribe(IMAGE_EVENTS.update, () => {
    lazyLoad.update();
  });

  PubSub.subscribe(IMAGE_EVENTS.load, (msg, { image }) => {
    lazyLoad.load(image, true);
  });

  PubSub.subscribe(IMAGE_EVENTS.loadAll, () => {
    lazyLoad.loadAll();
  });
});
