// node-modules
import svg4everybody from 'svg4everybody';
import fitTextarea from 'fit-textarea';

// assets
import 'styles/main.scss';
import 'fonts';

// default components
// init on DOMContentLoaded immediately
import '@/components/header/header.scripts';
import '@/components/social/social.scripts';
import '@/components/logo/logo.scripts';
import '@/components/menu/menu.scripts';
import '@/components/footer/footer.scripts';
import '@/components/newsletter/newsletter.scripts';
import '@/components/info-block/info-block.scripts';
import '@/components/two-halves-section/two-halves-section.scripts';
import '@/components/image/image.scripts';
import '@/components/news/news.scripts';
import '@/components/button/button.scripts';
import '@/components/lang-list/lang-list.scripts';
import '@/components/contact-list/contact-list.scripts';
import '@/components/group-list/group-list.scripts';
import '@/components/video/video.scripts';
import '@/components/title-with-text/title-with-text.scripts';
import '@/components/steps-list/steps-list.scripts';
import '@/components/gallery/gallery.scripts';
import '@/components/aside-title/aside-title.scripts';

// function components
// init on function call
import { initPreloader } from 'components/preloader/preloader.scripts';
import { initCoverSlider } from 'components/cover/cover.scripts';
import { initSlider } from '@/components/slider/slider.scripts';
import { smoothScroll } from '@/scripts/utils/smooth-scroll';
import { scrollAnimations } from '@/scripts/utils/scroll-animations';
import { initLetters } from '@/components/letters/letters.scripts';
import { toggleScrollbarWidth } from '@/scripts/utils/toggle-scrollbar-width';
import { blogShuffle } from '@/scripts/blog';
import { initTabs } from '@/components/tabs/tabs.scripts';
import { initMap } from '@/components/google-map/google-map.scripts';
import { contactForm } from '@/components/contacts-form/contacts-form.scripts';

// polyfills
import 'dom4';

// functional components
import { sayHello } from '@/scripts/utils/sayHello';

document.addEventListener('DOMContentLoaded', () => {
  svg4everybody();
  sayHello();

  // scrollbar compensation
  toggleScrollbarWidth(true);

  // cover slider
  initCoverSlider();

  // init blog grid
  blogShuffle();

  // init tabs
  initTabs();

  // smooth scroll
  const scrollSections = document.querySelectorAll('[data-scroll-section]');
  smoothScroll.setSections(scrollSections);
  smoothScroll.startup();

  // lettering
  initLetters(document);

  // scroll animations
  scrollAnimations(document);

  // sliders
  document.querySelectorAll('[data-carousel-slider]')
    .forEach((el) => initSlider(el));

  // init maps
  document.querySelectorAll('[data-google-map]').forEach(initMap);

  // fit textareas
  const textareas = document.querySelectorAll('textarea');
  fitTextarea.watch(textareas);

  // preloader
  initPreloader();

  //init forms
  document.querySelectorAll('[data-form]').forEach(contactForm);
});
