export const DOCUMENT_ELEMENT = document.documentElement;
export const ROOT_ELEMENT = document.querySelector('[data-role="root"]');
export const SCROLL_ELEMENT = document.body;
export const ROOT_CLASS = 'application';
export const SCROLLBAR_PADDING_SELECTORS = ['body', '[data-role="preloader"]', 'header'];
export const SCROLLBAR_MARGIN_SELECTORS = ['[data-role="scroll-to-top"]'];
export const SELECTORS = {
  // triggers
  active: 'is-active',
  hidden: 'is-hidden',
  disabled: 'is-disabled',
  current: 'is-current',
  loading: 'is-loading',
  loaded: 'is-loaded',
  invalid: 'is-invalid',

  // modals
  menu_opened: 'menu-opened',
  has_modal: 'has-modal',
  has_cookies: 'has-cookies',
  has_preloader: 'has-preloader',
  preloader_start: 'preloader-start',
  video_modal_opened: 'video-modal-opened',
  gallery_modal_opened: 'gallery-modal-opened',

  // scroll
  no_scroll: 'no-scroll',

  main_wrapper: '.ccm-page',
  edit_mode: '.ccm-edit-mode',
};

export const KEY_CODES = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  esc: 27,
  space: 32,
  arrow_left: 37,
  arrow_up: 38,
  arrow_right: 39,
  arrow_down: 40,
  comma: 44,
  left_window_key: 91,
};

export const BREAKPOINTS = {
  l: 1023,
  s: 639,
};

export const EVENTS = {
  modalToggle: Symbol('MODAL:TOGGLE'),
  menuToggle: Symbol('MENU:TOGGLE'),
  loadingStart: Symbol('LOADING:START'),
  loadingEnd: Symbol('LOADING:END'),
  ctaHide: Symbol('CTA:HIDE'),
  ctaShow: Symbol('CTA:SHOW'),
};
