import './contacts-form.styles.scss';
import validate from './validate';
import { smoothScroll } from '@/scripts/utils/smooth-scroll';

export function contactForm(form) {
  const setUpHideButton = (message) => {
    const hide = message.querySelector('[data-message-hide]');

    hide.addEventListener('click', () => {
      message.classList.add('alert-message_hidden');
      setTimeout(smoothScroll.reflowSections, 200);
    });
  };

  async function onFormSubmit(e) {
    e.preventDefault();

    const success = form.querySelector('[data-success-message]');
    const error = form.querySelector('[data-error-message]');
    success.classList.add('alert-message_hidden');
    error.classList.add('alert-message_hidden');

    setUpHideButton(error);
    setUpHideButton(success);

    fetch(form.getAttribute('action'), { method: 'post', body: new FormData(form) })
      .then((res) => {
        const message = res.ok ? success : error;
        message.classList.remove('alert-message_hidden');
        setTimeout(smoothScroll.reflowSections, 200);

        if (res.ok) {
          form.reset();
        }
      }, () => {
        error.classList.remove('alert-message_hidden');
      });
  }

  const init = () => {
    validate(form);

    form.addEventListener('submit', onFormSubmit);
  };

  return init();
}
