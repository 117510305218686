import './google-map.styles.scss';
import GoogleMapsLoader from 'google-maps';
import mapStyles from './styles.json';
import isIe from '@/scripts/utils/detect-ie';

export function initMap(el) {
  const {
    key, options, coords, markerImage,
  } = el.dataset;
  const { lat, lng } = JSON.parse(coords);
  const toggles = document.querySelectorAll('[data-location-toggle]');

  let Google; // eslint-disable-line

  GoogleMapsLoader.KEY = key;

  function setMapCenter(position, map, marker) {
    map.setCenter(position);
    marker.setPosition(position);
  }

  return GoogleMapsLoader.load((google) => {
    const center = new google.maps.LatLng({ lat, lng });

    const map = new google.maps.Map(el, {
      center: { lat, lng },
      styles: mapStyles,
      ...JSON.parse(options),
    });

    const marker = new google.maps.Marker({
      position: center,
      map,
      icon: isIe() ? null : markerImage,
      optimized: false,
      origin: isIe() ? null : new google.maps.Point(16, 16),
    });

    marker.setMap(map);

    toggles.forEach((item) => {
      item.addEventListener('click', () => {
        const position = JSON.parse(item.dataset.locationToggle);

        setMapCenter(position, map, marker);
      });
    });

    Google = google;
  });
}
