// constants
import { DOCUMENT_ELEMENT, SELECTORS } from '@/scripts/constants';

// assets
import './menu.styles.scss';


document.addEventListener('DOMContentLoaded', () => {
  const menuTrigger = document.querySelector('[data-menu-trigger]');
  if (!menuTrigger) {
    return;
  }
  menuTrigger.addEventListener('click', () => {
    DOCUMENT_ELEMENT.classList.toggle(SELECTORS.menu_opened);
    DOCUMENT_ELEMENT.classList.toggle(SELECTORS.no_scroll);
  });
});
