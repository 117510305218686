// single request animation frame call per project

const callbacks = [];

(function animationFrame(t) {
  callbacks.forEach(callback => callback(t));
  requestAnimationFrame(animationFrame);
}());

export default function (callback) {
  callbacks.push(callback);

  // return cancel callback
  return () => {
    const i = callbacks.indexOf(callback);
    callbacks.splice(i, 1);
  };
}
